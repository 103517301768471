import React, {Component} from 'react';
import styled from 'styled-components';
import SEO from '../components/core/Seo';
import GlobalStyle from '../styles/global';
import {Container, Row, Col} from 'styled-bootstrap-grid';
import Background from '../components/age/AgeVerificationBackground';
import fonts from '../styles/fonts';
import BlueLineImg from '../resources/images/brewery/blue-line.svg';
import SmallBrokenLineImg from '../resources/images/small-broken-line.svg';
import LogoImg from '../resources/images/logo.svg';
import {navigate} from 'gatsby';
import Cookies from 'universal-cookie';
import Form from '../components/age/Form';
import MailChimpSubscribe from '../components/core/MailchimpSubscribe';

const VerticalAlign = styled.div`
      width: 100%;
      min-height: 100vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;  
      box-sizing: border-box;
`;

const ContainerStyled = styled(Container)`
  text-align: center;
  margin-top: 0px;
  margin-bottom: 65px;
`;

const Question = styled.div`
     ${fonts.swissBlackExtended};
    color: white;
    font-size: 21px;
    line-height: 27px;
    text-transform: uppercase;
    text-align: center;
    max-width: 486px;
    margin: auto;
    padding-bottom: 15px;
`;

const BlueLine = styled.img`
    padding-top: 30px;
    padding-bottom: 20px;
`;

const Logo = styled.img`
    padding-bottom: 110px;
`;

const RowStyled = styled(Row)`
  max-width: 100vw;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
`

const url = 'https://lostatsea.us3.list-manage.com/subscribe/post?u=55d481c245c181cbf26a7e965&amp;id=bd17e687b2';

class AgeVerification extends Component {

  calculateAge = () => {
    const cookies = new Cookies();
    cookies.set('legalAge', true, { path: '/' , expires: this.getNextMonth()});
    navigate('/');
  };

  getNextMonth = () => {
    const current = new Date();
    const nextMonth = new Date();

    nextMonth.setFullYear(current.getFullYear(), current.getMonth(), current.getDate()+30);

    return nextMonth;
  }

  render() {
    const path = this.props.location.pathname;
    return (<>
      <GlobalStyle/>
      <SEO title="Lost At Sea"
           description="A storm is brewing." fullTitle={true}
           path={path}/>
      <Background/>
      <VerticalAlign>
        <ContainerStyled>
          <RowStyled>
            <Col>
              <Logo src={LogoImg}/>
            </Col>
            <Col>
              <Question>You’re bold enough to get Lost at Sea, but are you old enough? </Question>
            </Col>
            <Col>
            </Col>
            <Col>
              <BlueLine src={BlueLineImg}/>
            </Col>
            <Col>
              <MailChimpSubscribe url={url}>
                <Form setCookie={this.calculateAge}/>
              </MailChimpSubscribe>
            </Col>
          </RowStyled>
        </ContainerStyled>
      </VerticalAlign>
    </>)
  }
}

export default AgeVerification;
