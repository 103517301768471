import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { graphql, StaticQuery } from 'gatsby';
import Overlay from '../../resources/images/dark-overlay.png';


const BackgroundStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
`;
const BackgroundImageStyled = styled(BackgroundImage)`
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
`;
const DarkOverlay = styled.img`
    position: absolute;
    width: 100%;
    height: 260px;
    bottom: 0;
    left: 0;
`;

const Background = () => (
  <StaticQuery query={graphql`
    query {
      background: file(relativePath: { eq: "age-verification-background.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 2500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `}
    render={data => {
      const imageData = data.background.childImageSharp.fluid;
      return (
        <BackgroundStyled>
          <BackgroundImageStyled fluid={imageData} fadeIn={"soft"} durationFadeIn={500} />
          <DarkOverlay src={Overlay} />
        </BackgroundStyled>
      )
    }
    }
  />
);

export default Background;
