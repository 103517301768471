import React, { useState, useEffect } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import SmallBrokenLineImg from "../../resources/images/small-broken-line.svg"

const InputContainer = styled.div`
  position: relative;
  display: block;
  width: 300px;
  margin: 0 auto 20px;
  display: flex;
  input {
    background: transparent;
    border: 1px solid ${colors.mint};
    border-radius: 0px;
    ${fonts.gilroyRegular}
    color: white;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    width: 100%;
    padding: 10px 0 10px 15px;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }
    ::placeholder,
    ::-webkit-input-placeholder {
      color: white;
      font-size: 14px;
    }
    :-ms-input-placeholder {
      color: white;
      font-size: 14px;
    }
  }
`

const BorderStyled = styled.div`
  position: absolute;
  top: 5px;
  right: -5px;
  background-color: transparent;
  border-bottom: 5px solid ${colors.mint};
  border-right: 5px solid ${colors.mint};
  height: 38px;
  width: 95%;
  z-index: -1;
`

const SmallBrokenLine = styled.img`
  height: 65px;
`

const StyledButton = styled.button`
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  border: 1px solid ${colors.mint};
  background: transparent;
  border-radius: 0;
  height: 44px;
  color: ${colors.white};
  text-transform: uppercase;
  ${fonts.gilroyBold};
  letter-spacing: 2.77px;
  font-size: 12px;
  position: relative;
  transition: background 0.5s, color 0.5s;
  -webkit-transition: background 0.5s, color 0.5s;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    background: white;
    color: ${colors.mint};
  }
`

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  width: 200px;
  margin: auto;
  margin-top: 20px;
`

const Form = props => {
  const [email, setEmail] = useState("")
  const { setCookie, submit, status } = props

  const handleSubmit = e => {
    e.preventDefault()

    email &&
      email.indexOf("@") > -1 &&
      submit({
        EMAIL: email,
      })

  }

  useEffect(() => {
    if (status) {
      setCookie()
    }
  }, [status])
  
  return (
    <form onSubmit={e => handleSubmit(e)}>
      <InputContainer>
        <input
          placeholder={"Email address"}
          type="email"
          name={"age-email"}
          id={"email"}
          required
          onChange={e => setEmail(e.target.value)}
        />
        <BorderStyled />
      </InputContainer>
      <SmallBrokenLine src={SmallBrokenLineImg} />
      <ButtonContainer>
        <StyledButton type={"submit"}>
          I am over 18
          <BorderStyled />
        </StyledButton>
      </ButtonContainer>
    </form>
  )
}

export default Form
